import { Http, type ApiResult } from '~/composables/useApiFetch'

export default new class news extends Http {

  public getAfterSales(data: object) {
    return this.get('/front/jm-after-sales', data, true)
  }

  public getLastAfterSalesByOrder(id: string) {
    return this.get(`/front/jm-after-sales/last-by-order/${id}`) as Promise<ApiResult<{
      /**
       * 订单的最后一个售后信息
       */
      last: any,
      /**
       * 订单的最后一个未完成的售后信息
       */
      lastProcessing: any,
    }>>
  }

  public getAfterSalesList(data: object) {
    return this.get('/front/jm-after-sales/list', data, true)
  }
  
  public getAfterSalesNums() {
    return this.get('/front/jm-after-sales/nums', {}, true)
  }
  


  public addAfterSales(data: object) {
    return this.clientPost('/front/jm-after-sales', data, true)
  }
  public putAfterSales(data: object) {
    return this.clientPut('/front/jm-after-sales', data, true)
  }

  public getRefundableGoods(data: object) {
    return this.get('/front/jm-after-sales/available', data, true)
  }
  
  public getAfterSalesDetails(id: string) {
    return this.get('/front/jm-after-sales/'+id, {}, true)
  }

  public afterWithdraw(data: object) {
    return this.post('/front/jm-after-sales/withdraw', data, true)
  }

  public afterSalesReturn(data: object) {
    return this.post('/front/jm-after-sales/return', data, true)
  }

  public getChangeableProducts(data: object) {
    return this.get('/front/jm-after-sales/changeable-products', data, true)
  }

  public getPayDiffUrl(id: string,type: string) {
    return this.get('/front/jm-after-sales/'+id+'/pay-diff-url/'+type, {}, true)
  }


  public getLogisticsCompany(data: object) {
    return this.get('/front/jm-logistics-company', data, true)
  }

  public getChangeableProductsSpecs(id:string,data: object) {
    return this.get(`/front/jm-after-sales/changeable-products/${id}/specs`, data, true)
  }


  public cancelAfterSales(id:string){
    return this.post(`/front/jm-after-sales/cancel-after-sales/${id}`,{},true)
  }
  // 取消售后原因
  public cancelAfterSalesReason(){
    return this.get(`/front/reason/cancel-after-sales`,{},true)
  }

}