import testApi from './test'
import userApi from './user'
import newsApi from './news'
import commodityApi from './commodity'
import associatedApi from './associated'
import advertisementApi from './advertisement'
import shoppingCartApi from './shoppingCart'
import orderApi from './order'
import appraiseApi from './appraise'
import customizedApi from "./customized";
import historyApi from "./history";
import noticeApi from "./notice";
import collectApi from "./collect";
import uploadApi from "./upload";
import afterSalesApi from "./afterSales";
import freightApi from "./freight";
import faqApi from './faq'
import contactUsApi from './contactUs'
import customJewelryApi from "./customJewelry";
export default {
  testApi,
  userApi,
  newsApi,
  commodityApi,
  faqApi,
  associatedApi,
	advertisementApi,
	shoppingCartApi,
	orderApi,
	appraiseApi,
  customizedApi,
  historyApi,
  collectApi,
	noticeApi,
	uploadApi,
	afterSalesApi,
	freightApi,
  contactUsApi,
  customJewelryApi
}