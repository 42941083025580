import { pageHeaderEmits } from 'element-plus'
import { Http } from '~/composables/useApiFetch'

export default new class news extends Http {

  // 获取评论
  public getAppraise(data: object) {
    return this.get('/jm-appraise', data, true)
  }
  public getAppraisePage(data: object) {
    return this.get('/jm-appraise/page', data, true)
  }
  public getAppraiseList(data: object) {
    return this.get('/front/customized/myAppraise', data, true)
  }
  public getAppraiseListNew(data: object) {
    return this.get('/front/customized/myAppraiseNew', data, true)
  }
	public helpOrBad(data: object) {
	  return this.get('/front/customized/helpOrBad', data, true)
	}
	public addAppraise(params:object){
		return this.post('/front/jm-appraise',params,true)
	}
  public getCountAppraise(data: object) {
    return this.get('/front/customized/countAppraise', data, true)
  }
	public delAppraise(id: string) {
	  return this.delete('/jm-appraise/'+id, {}, true)
	}
  public delAppraiseImage(data: object) {
	  return this.get('/front/customized/deleteImages', data, true)
	}
  public updateAppraise(params:object){
		return this.put('/front/jm-appraise',params,true)
	}
  public getCountAppraiseNew(data:object){
		return this.get('/front/customized/countAppraiseNew', data, true)
	}
  public getAppraisePageNew(data: object) {
    return this.get('/front/jm-appraise/page', data, true)
  }
}
