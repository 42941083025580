import { Http } from '~/composables/useApiFetch'

export default new class news extends Http {

  // 获取资讯列表
  public getNewsList(data: object) {
    return this.get('/jm-news', data, false)
  }
  
  // 获取单个资讯详情
  public getNewsDetail(urlComponent: string) {
    return this.get('/front/news/' + urlComponent, {}, false)
  }
}