import { Http } from '~/composables/useApiFetch'

export default new class product extends Http {
	// 分页查询商品列表
	public page(params:any){
		return this.get('/jm-f-a-q/page',params,false)
	}
	public getFaq(params:any){
		return this.get('/jm-f-a-q',params,false)
	}
	public getFaqDetail(id:string){
		return this.get('/front/faq/url/'+id,{},false)
	}

  public getFaqByUrlComponent(urlComponent: string) {
    return this.get(`/front/faq/url/${urlComponent}`, {}, false);
  }

  public searchFaq(param: any ) {
    return this.get(`/front/faq/search`, param, false);
  }
}