import { Http } from '~/composables/useApiFetch'

export default new class news extends Http {

  // 获取通知
  public getNotice(data: object) {
    return this.get('/front/jm-consumer-notice', data, true)
  }
  public clientGetNotice(data: object) {
    return this.clientGet('/front/jm-consumer-notice', data, true)
  }

  public readNotice(data: object) {
    return this.clientPut('/jm-consumer-notice', data, true)
  }

  public send(data: object) {
    return this.post('/front/send', data, true)
  }

  public deleteNotice(id: any) {
    return this.delete(`/front/jm-consumer-notice/${id}`, {}, true)
  }
  
}