import { Http } from '~/composables/useApiFetch'

export default new class news extends Http {

  // 获取广告
  public getAdvertisement(data: object) {
    return this.get('/front/jm-advertisement', data, false)
  }
  // 获取广告分页
  public getAdvertisementPage(data: object) {
    return this.get('/front/jm-advertisement/page', data, false)
  }
  
}