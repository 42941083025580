import { Http } from '~/composables/useApiFetch'
import { useMainStore } from '@/store/'

export default new class product extends Http {
	// 添加收藏
	public async addCollect(params:object){
		try {
			const res = await this.post('/front/customized/collectEdit', params, true)
			const mainStore = useMainStore()
			mainStore.getCollectList()
			return Promise.resolve(res)
		} catch (error) {
			return Promise.reject(error)
		}
	}
	public getCollectList(params:object = {}){
		return this.get('/front/commodity/page', { ...params, collectFlag: true }, true)
	}
	// 清空收藏列表/jm-collect/batch
	public async delCollectList(body: any){
		try {
			const res = await this.delete('/front/jm-collect/batch', body, true)
			const mainStore = useMainStore()
			mainStore.getCollectList()
			return Promise.resolve(res)
		} catch (error) {
			return Promise.reject(error)
		}
	}


	public syncCollect(params:any){
		this.post('/front/customized/syncCollect', params, true)
	}

	
	
}