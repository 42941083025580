// store/filters.ts
import { defineStore } from 'pinia'
// const runtimeConfig = useRuntimeConfig()

function setCacheCurrentUser(currentUser: any) {
  const expires = new Date();
  expires.setMonth(expires.getMonth() + 1);
  useCookie('_PICU_', { expires }).value = btoa(JSON.stringify(currentUser));
}

function getCacheCurrentUser() {
  const str1 = useCookie('_PICU_').value;
  if (!str1) {
    return;
  }
  const str2 = atob(str1);
  if (!str2) {
    return;
  }
  try {
    return JSON.parse(str2);
  } catch (e: any) {
    return undefined;
  }
}

export const useMainStore = defineStore({
  id: 'main-store',
  state: () => {
    return {
      currentUser: getCacheCurrentUser(),
      // 是否已查询过当前用户，未查询过不能直接当做 currentUser undefined 为未登录
      currentUserSolved: false,
      // deployWebsite: runtimeConfig.public.deployWebsite,
      currencyNow: {
        currencySymbol: 0,
        exchangeRate: 0,
      },
      collectTotal: 0,

      collectList: [],
      shoppingList: [],
      shoppingTotal:0,
      shoppingData: {
        goodsMoney: 0,
        goodsDiscount: 0,
        goodsSubtotal: 0,
        itemSubtotal: 0,
      },

      specCurrentIndex: -1
    }
  },

  getters: {
    collectIds: state => state.collectList.map((item: any) => item.id) 
  },

  actions: {
    async getCurrentUser() {
      const { userApi } = useApi()
      try {
        const result = await userApi.getCurrentUser()
        if(result.success && result.data){
          this.currentUser = result.data
          setCacheCurrentUser(this.currentUser);
          this.currentUserSolved = true;
          return
        }
      } catch (error) {
        console.warn(error)
      }
      this.currentUser = undefined
      setCacheCurrentUser(this.currentUser);
      this.currentUserSolved = true;

      // const { userApi } = useApi()
      // const id = useCookie('conId')
      // if(id.value){
      //   try {
      //     const result = await userApi.getUserInfo(id.value)
      //     if(result.success && result.data){
      //       this.currentUser = result.data
      //       return
      //     }
      //   } catch (error) {
      //     console.error(error)
      //   }
      // }
      // this.currentUser = undefined

    },
    changeCollectTotal(value: number) {
      this.collectTotal = value
    },
    changeCurrencyNow(e: any){
      localStorage.setItem('muslb-currency-now', JSON.stringify(e));
      this.currencyNow = e;
    },

    async getCollectList() {
        const { collectApi } = useApi()
        if(this.currentUser){
          try {
            const res: any = await collectApi.getCollectList({current: 1,pageSize: 100})
            this.collectList = res.data.records || []
            this.collectTotal = res.data.total
            return Promise.resolve(res)
          } catch (error) {
              console.log(error);
              return Promise.reject(error)
          }
        }else{
          const collectLists = JSON.parse(localStorage.getItem('collectLists'))
          console.log(collectLists)
          if(collectLists){
            this.collectList = collectLists
            this.collectTotal = collectLists.length
          }else{
            this.collectList = []
            this.collectTotal = 0
          }
        }
    },
    getShoppingList(params: any = { current: 1,pageSize: 100 }) {
      const { shoppingCartApi } = useApi()
      if(this.currentUser){
        return shoppingCartApi.getShoppingCart(params).then((res:any)=>{
          if(Array.isArray(res.data?.pageMap?.records)){
            // 于群： 这两个 goodsMoney是商品价格 itemSubtotal是商品+包装+保护计划
            this.shoppingData.goodsMoney = res.data.goodsMoney
            this.shoppingData.goodsDiscount = res.data.goodsDiscount
            this.shoppingData.goodsSubtotal = res.data.goodsSubtotal
            this.shoppingData.itemSubtotal = res.data.itemSubtotal
            this.shoppingList = res.data?.pageMap?.records
            this.shoppingTotal= res.data?.pageMap?.records.reduce((total:any, obj:any) => total + obj.quantity, 0);
            
          }else{
            this.shoppingData = {
              goodsMoney: 0,
              goodsDiscount: 0,
              goodsSubtotal: 0,
              itemSubtotal: 0,
            }
            this.shoppingList = []
            this.shoppingTotal=0
          }
          return res
        })
      }else{
        const storageData = localStorage.getItem("shoppingCartLocalItems");
        console.log(storageData)
        let items = [];
        if (storageData) {
          try {
            items = (JSON.parse(storageData) || []).filter(i => i);
          } catch (error) {
            console.error(error)
            items = []
          }
          this.shoppingList = items
          this.shoppingTotal= items.reduce((total:any, obj:any) => total + obj?.quantity, 0);

          const totalGoodsMoney = items.reduce((total, item) => total + (item?.goodsInformation?.goodsPrice * (item?.quantity || 1)), 0);
           // 于群： 这两个 goodsMoney是商品价格 itemSubtotal是商品+包装+保护计划
          this.shoppingData = {
            goodsMoney: totalGoodsMoney,
            goodsDiscount: 0,
            goodsSubtotal: totalGoodsMoney,
            itemSubtotal: 0,
          }
        }else{
          this.shoppingList = []
          this.shoppingTotal=0
          this.shoppingData = {
            goodsMoney: 0,
            goodsDiscount: 0,
            goodsSubtotal: 0,
            itemSubtotal: 0,
          }
        }
        return Promise.resolve({
          success: false
        })
      }
      
    },
    setShoppingList(list: any[]){
      this.shoppingList = list

      this.shoppingTotal=0
    },
    restCollect() {
        this.collectTotal = 0

        this.collectList = []
    }
  },
})