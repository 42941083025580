import { Http } from '~/composables/useApiFetch'

export default new class commodity extends Http {

  // 获取商品列表
  // public getCommodityList(data: object) {
  //   return this.get('/jm-commodity', data, false)
  // }
  public getCommodityList(params: object, data?: any) {
    return this.get('/front/commodity/page', params, true, )
  }

  public getCommodityByLibrary(params: object) {
    return this.post('/front/customized/getCommodityByLibrary', params, true)
  }
  public getCommodityByLibraryJock(params: object) {
    return this.get('/front/commodity/page', params, true)
  }
  
  // 获取单个商品详情
  public getCommodityDetail(id: string) {
    return this.get('/front/customized/getGoodsInfo/' + id, {}, true)
  }

  // public getCommodityTreeType(params : object) {
  // 	return this.get('/jm-commodity-type/tree', params, false)
  // }
  public getCommodityType(params: object) {
    return this.get('/front/jm-commodity-type', params, false)
  }

  public getFastAttrMap() {
    // return this.get('/front/jm-commodity-specifications/attr-maps');
    return this.get('/front/jm-commodity-attribute/attr-maps');
  }

  // public getCommodityTypeList(id : string) {
  // 	return this.get('/jm-commodity-type/' + id, {}, false)
  // }
  // public getCommoditySpecifications(params: object) {
  //   return this.get('/front/jm-commodity-specifications', params, false)
  // }
  // public getCommoditySpecificationsDetail(id: string) {
  //   return this.get('/front/jm-commodity-specifications/' + id, {}, false)
  // }

  // public getCommoditySpecificationsInfo(id: string) {
  //   return this.get('/front/jm-commodity-specifications/info/' + id, {}, false)
  // }
  // 批量根据属性id获取属性详情
  public getCommoditySpecificationsSchemeArrayByYangfan(ids: string[]) {
    return this.post('/front/customized/attributeList', {}, false)
  }

  public getCommoditySpecificationsScheme(id: string) {
    return this.get('/front/jm-commodity-specifications/schemeInfo/' + id, {}, false)
  }

  public getSpecificationsListByGoodsId(goodsId: string,specificationsNameOne:string) {
    return this.post('/front/customized/getSpecificationsListByGoodsId?goodsId=' + goodsId+'&specificationsNameOne='+specificationsNameOne, {}, false)
  }
  // public getCommoditySpecificationLibrary(params: object) {
  //   return this.post('/jm-specification-library', params, true)
  // }
  public getCommoditySpecificationLibraryNew(params: object) {
    // return this.get('/front/customized/specificationLibrary', params, true)
    return this.get('/front/customized/filterList', params, true)
  }
  public getCommoditySpecification(params: string) {
    return this.post('/front/customized/getSpecLibMapBySpecificationName?' + params, '', true)
  }

  public getCommodityScheme(data: object) {
    return this.get('/front/jm-commodity-scheme', data, true)
  }

  public getAssociated(data: object) {
    return this.get('/front/customized/getAssociated', data, true)
  }
  public getFront() {
    return this.get('/front/jm-type-face', {}, true)
  }

  public selectSimpleCustomizationSchema(data: any) {
    return this.get('/front/commodity/simple-customization', data);
  }

  // public getCommoditySpec (id: String){
  // 	return this.get('/front/jm-commodity-specifications/'+id, {}, true)
  // } 

  // 简单定制和常规商品获取副戒
  public getAccessoryRingList(data:any){
    return this.post("/front/accessoryRing/filterList", data, true)
  }

  public getSeo(goodsId: string) {
    return this.get(`/front/customized/getSeo/${goodsId}`, {},true);
  }

  public createGiftCard(data:any){
    return this.post("/front/gift-card-order/create", data, true)
  }
  
}