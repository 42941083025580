import { Http } from '~/composables/useApiFetch'

export default new class news extends Http {

  // public postMessage() {
  //   return this.post(`/payment/stripePay/after-sales/${id}`, {}, true);
  // }

  public customJewelryFormPost(data: object,){
    return this.post('/front/collect-form/custom-jewelry',data,true)

  }
}