import { Http } from '~/composables/useApiFetch'

export default new class history extends Http {

  // 获取浏览记录
  public getBrowsingHistory(data: object) {
    return this.get('/jm-browsing-history', data, true)
  }
  public getHistory(data: object = {}) {
    return this.get('/front/commodity/page', { ...data, historyFlag: true }, true)
  }
}