import { Http } from '~/composables/useApiFetch'

export default new class news extends Http {

  // 获取资讯列表
  public getAttributeListByGoods(data: object) {
    return this.post('/front/customized/getAttributeListByGoodsId', undefined, false, data)
  }
	public getCommodityAttr(data : object) {
		return this.post('/front/customized/getCommodityByAttributes2', data, false)
	}
  public getPreviewCommodityAttr(data : object) {
		return this.post('/front/customized/getPreviewCommodityByAttributes', data, false)
	}
  public getCommodityCategory(data:object){
    return this.post("/front/customized/getAttributeListBy",data,false)
  }
  public userVerify(){
    return this.get("/front/customized/userVerify",'',true)
  }
  public verifyStatus(){
    return this.get("/front/customized/verifyStatus",'',true)
  }
}