const fetch = async (url: string, options?: any, tokenAuth?: boolean, showToast = true) => {
  // const runtimeConfig = useRuntimeConfig()

  // const { apiProxyBaseUrl } = runtimeConfig.public
  // const baseUrl = process.client ? '/api' : apiProxyBaseUrl
  const baseUrl = '/api'

  const access_token = useCookie('access_token')
  let header: {
    'Content-Type': string,
    Authorization?: string
  } = {
    'Content-Type': 'application/json'
  }
  if (access_token.value && tokenAuth) {
    header.Authorization = access_token.value
  }
  
  try {
    await nextTick()
    const { data, error } = await useFetch(baseUrl + url, { ...options, headers: header,
      async onResponse({ request, response, options }) {
        // const r = await response.json()
        // console.log("[fetch response]", request, response.status, response.body);
      },
      async onResponseError({ request, response, options }) {
        if(process.server){
          throw createError({
            statusCode: response.status,
            statusMessage: url,
            message: response.statusText,
          })
        }

        if(response.status === 401){
          useCookie('access_token').value = '' 
          useCookie('conId').value = '' 
          const isLoginApi = url === '/front/customized/getConsumerByCode'
          if(!isLoginApi && tokenAuth && process.client && useState('loginVisible').value === false){
            ElMessage.warning('You need to login!')
            useState('loginVisible').value = true
          }
        }
        // console.log(
        //   "[fetch response error]",
        //   request,
        //   response.status,
        //   response.body
        // )
      },
    })
    const result: any = data?.value as any
    
    if (error?.value) {
      return Promise.reject(error?.value)
    }else if(result && typeof result === 'object'){
      if('status' in result){
        if(result.status !== 'ok'){
          return Promise.reject(result)
        }else{
          return Promise.resolve(result)
        }
      }
      if(result?.success !== true){
        return Promise.reject(result)
      }

      return Promise.resolve(result)
    }

    return Promise.reject(result)
  } catch (err) {
    console.log(err)
    return Promise.reject(err)
  }
}

/** 仅使用前端请求 */
export const clientFetch = async (url: string, options?: any, tokenAuth?: boolean, showToast = true) => {
  // const runtimeConfig = useRuntimeConfig()

  // const { apiProxyBaseUrl } = runtimeConfig.public
  // const baseUrl = process.client ? '/api' : apiProxyBaseUrl
  const baseUrl = '/api'

  const access_token = useCookie('access_token')
  let header: {
    'Content-Type': string,
    Authorization?: string
  } = {
    'Content-Type': 'application/json'
  }
  if (access_token.value && tokenAuth) {
    header.Authorization = access_token.value
  }
  
  try {
    await nextTick()
    let body: Blob | string | null = ['post', 'put'].includes(options.method) ? JSON.stringify(options.body) : null;
    if (process.server && body) {
      body = new Blob([body]);
    }
    const r = await window.fetch(baseUrl + url, {
      method: options.method || 'get', 
      headers: header,
      body,
    });
    if (r.status === 401) {
      useCookie('access_token').value = '' 
      useCookie('conId').value = '' 
      const isLoginApi = url === '/front/customized/getConsumerByCode'
      if(!isLoginApi && tokenAuth && process.client && useState('loginVisible').value === false){
        ElMessage.warning('You need to login!')
        useState('loginVisible').value = true
      }
    }
    const result = await r.json();
    if (result.status != 'ok' && !result.success) {
      throw new Error(result.errorMessage);
    }

    return result;
  } catch (err) {
    console.log(err)
    return Promise.reject(err)
  }
}

function filterError(err: any, showToast: boolean = true) {
  if(process.client && showToast){
    let msg = err?.errorMessage || err?.message || (typeof err === 'string' && err)
    ElMessage.error(msg || 'Server error, please contact the administrator!')
  }
  return Promise.reject(err)
}
export class Http {
  get(url: string, params?: any, tokenAuth: boolean = true, showToast?: boolean) {
    return fetch(url, { method: 'get', params }, tokenAuth).catch((e => filterError(e, showToast)))
  }
  clientGet(url: string, params?: any, tokenAuth: boolean = true, showToast?: boolean) {
    return clientFetch(url, { method: 'get', params }, tokenAuth, showToast).catch((e => filterError(e, showToast)))
  }

  post (url: string, body?: any, tokenAuth: boolean = true, params?: any, showToast?: boolean) {
    return fetch(url, { method: 'post', body, params }, tokenAuth, showToast).catch((e => filterError(e, showToast)))
  }

  clientPost (url: string, body?: any, tokenAuth: boolean = true, params?: any, showToast?: boolean) {
    return clientFetch(url, { method: 'post', body, params }, tokenAuth, showToast).catch((e => filterError(e, showToast)))
  }

  put (url: string, body?: any, tokenAuth: boolean = true, showToast?: boolean) {
    return fetch(url, { method: 'put', body }, tokenAuth, showToast).catch((e => filterError(e, showToast)))
  }

  clientPut (url: string, body?: any, tokenAuth: boolean = true, showToast?: boolean) {
    return clientFetch(url, { method: 'put', body }, tokenAuth, showToast).catch((e => filterError(e, showToast)))
  }

  delete (url: string, body?: any, tokenAuth: boolean = true, showToast?: boolean) {
    return fetch(url, { method: 'delete', body }, tokenAuth, showToast).catch((e => filterError(e, showToast)))
  }
}

interface ApiSuccess<T> {
  success: true,
  data: T
}
interface ApiFail {
  success: false,
  data: any
}
export type ApiResult<T> = ApiSuccess<T> | ApiFail;

// 旧的，个人选择不这么用
export const useApiFetch = async <T>(path: string, method: string, params?: object, onEvents?: any) => {
  const runtimeConfig = useRuntimeConfig()
  const { baseUrl } = runtimeConfig.public

  const {value} = useCookie<string>('access_token')



  let header: {
    'Content-Type': string,
    Authorization?: string
  } = {
    'Content-Type': 'application/json'
  }
  let accessToken = null
  if (value) {
    accessToken = value
    header.Authorization = value
  }

  let option = {}
  if (method === 'GET') {
    option = {
      method: method,
      headers: header,
      params: params
    }
  } else {
    option = {
      method: method,
      headers: header,
      body: params
    }
  }
  // 这边应该还能优化一下
  const res = await useFetch<T>(() => baseUrl + path, {...option})
  // 处理接口报错
  if (!(res.data.value as any).success) {
    ElMessage.error((res.data.value as any).errorMessage || 'Server error, please contact the administrator!')
  }
  return res
  
  // baseURL: apiUrl,
  // method: method,
  // retry: 1,
  // cache: false,
  // headers: {
  //   'Content-Type': 'application/json',
  //   Authorization: `Bearer ${accessToken}`
  // },
  // body: body,
  // params: query,
  // ...onEvents
}
