import { Http } from '~/composables/useApiFetch'

export default new class news extends Http {

  // 获取关联商品
  public getAssociatedGoods(data: object) {
    return this.get('/jm-associated-goods', data, false)
  }
	
	public getAssociated (data: object){
		return this.get('/front/customized/getAssociated', data, true)
	}
  public getAssociatedList (data:object){
    return this.get("/front/commodity/buy-more",data,true)
  }
}