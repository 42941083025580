import { Http } from '~/composables/useApiFetch'

export default new class user extends Http {
  // 获取用户信息
  public getCurrentUser() {
    const t = (Date.parse(new Date() + '') + '').substring(0, 10)
    return this.get('/front/customized/getConsumerByCode', { _t: t }, true, false)
  }
  // 登录验证码 --- 现在不用这个功能了
  public getRandomImage(time: string, t: string) {
    return this.get('/sys/randomImage/' + time, { _t: t })
  }
  // 登录接口
  public userLogin(form: object) {
    return this.post('/front/login/account', form, false).then((r: any) => {
      if(r){
        useCookie('access_token',{maxAge: 60 * 60 * 24 * 30}).value = r?.token
		useCookie('conId',{maxAge: 60 * 60 * 24 * 30}).value = r?.conId
      }
      return r
    })
  }
	// 退出登录
	public outLogin(){
		return this.post('/login/outLogin', null, true, null)
	}

  // 数据字典
  public getDictList() {
    return this.get('/sys/dict/items', {}, false)
  }
  public getUserInfo(id: string){
  	return this.get('/front/jm-consumer/'+id,{},true, false)
  }
	public updateUserInfo(data: object,params: object){
			return this.post('/front/customized/updateUserInfo',data,true,params)
		}
	public getUserAddress(data: object){
	  	return this.get('/front/jm-consumer-address',data,true)
	  }
	public addUserAddress(data: any,params: object){
	  	return this.post('/front/jm-consumer-address',data,true,params)
	  }
	public updateUserAddress(data: object){
	  	return this.put('/front/jm-consumer-address',data,true)
	  }
	public delUserAddress(id: string){
	  	return this.delete('/front/jm-consumer-address/'+id,{},true)
	  }
		public setDefaultAddress(id: string){
		  	return this.get('/front/customized/setDefaultAddress/'+id,{},true)
		  }
  public getUserPoints(data: object){
  	return this.get('/front/jm-consumer-points/page',data,true)
  }
	// 获取积分规则
  public getPointsList(data: object){
	return this.get('/front/customized/getPointsList',data,true)
  }
	// 货架
	// public getCommodityShelves(data: object){
	// 	return this.get('/jm-commodity-shelves/page',data,true)
	// }
	public getCommodityShelves(data: string){
		return this.get('/front/customized/commodityShelves/' + data, {},true)
	}
	// 消息未读数
	public getUnread(){
		return this.get('/front/customized/unread', {},true)
	}

	
  // 导航栏
	public getNavigation(){
		return this.get('/front/jm-navigation-bar', null, true)
	}
	

  // 币种汇率
	public getCurrency(data: object){
		return this.get('/front/jm-currency-exchange-rate', data, false)
	}

	public getConsumerVipTiers(data: object){
		return this.get('/front/customized/getConsumerVipTiers',data,true)
	}

	public getVipTiers(data: object){
		return this.get('/front/customized/getVipTiers',data,true)
	}
	// 注册用户
	public createUser(data: object,params: any){
		return this.post('/front/jm-consumer',data,false,params)
	}
	// 第三方登录
	public otherLogin(data: object){
		return this.post('/front/register/oneClickLogin',data,false)
	}
	// 用户订阅信息
	public getConsumerSubscribeList(data: object){
		return this.get('/front/customized/getConsumerSubscribeList',data,true)
	}
	// 保存用户订阅信息
	public saveConsumerTake(data: object){
		return this.post('/front/customized/saveConsumerTake',data,true)
	}
	// 用户认证
	public authentication(data: object){
		return this.put('/front/jm-consumer/authentication',data,true)
	}
	// 分享
	public share(data: object){
		return this.post('/front/jm-consumer/userSharing',data,true)
	}
	
	public sendChangePassword(email: String){
		return this.get('/front/jm-consumer/sendChangePassword/'+email,{},true)
	}

	public ChangePassword(data: Object){
		return this.post('/front/jm-consumer/changePassword',data,true)
	}
	public allowCountries(data: Object){
		return this.get('/front/freight/allow-countries',data,true)
	}
	public getPhoneCodeList(data: Object){
		return this.get('/front/customized/getPhoneCodeList',data,true)
	}
	// 底部注册
	public defaultRegistration (data: Object) {
		return this.post('/front/jm-consumer/defaultRegistration', data, false, {}, false)
	}

	// 邮箱认证
	public userVerifyWithCode(data: Object){
		return this.get('/front/customized/userVerifyWithCode',data,true)
	}

}