import { Http } from '~/composables/useApiFetch'

export default new class test extends Http {

  /**
   * 获取热门文章
   */
  public getHot(): Promise<{ items: Array<{ id: number, name: string }>}> {
    return this.get('/app/v1/article/hotView')
  }

  public getCurrentUser() {
    return this.get('/currentUser', { _t: (Date.parse(new Date() + '') + '').substring(0, 10) })
  }
}