// import { useApiFetch } from "./useApiFetch";
import api from "~/api/index"
import { useRouter } from "#app";
import { useMainStore } from '@/store/'
import { storeToRefs } from 'pinia'

// export const accessToken = useCookie<AccessToken>('access_token')
// export const refreshToken = useCookie<RefreshToken>('refreshToken')

// export const Login = async (form: AuthForm): Promise<boolean> => {
//   // console.log("api中：", form)
//   const { data, error } = await useApiFetch<LoginResUt>('/login/account', 'POST', {...form}, {
//     // 这个东西可能有用，谁会用的话可以看看，优化一下
//     // async onResponse({ request, response, options, }) {},
//     // async onRequestError({ request, options, error }) {},
//   })
//   // 存token
//   if (!error.value && data.value) {
//     // toRefs(data.value).token.value
//     const refs: any = toRefs(data.value)
//     accessToken.value = refs.token.value
//     return true
//   }

//   return false
// }

// 获取数据字典信息
// export const getDictList = async (): Promise<object> => {
//   return new Promise((resolve) => {
//     useApiFetch<LoginResUt>('/sys/dict/items', 'GET').then(res => {
//       const { data } = res
//       // 处理数据字典返回的数据
//       itemsToFastDicts((data.value as any).data)
//       resolve({})
//     })
//   })
// }

// 处理数据字典的方法
const itemsToFastDicts = (allItems: any) => {
  if (!Array.isArray(allItems)) {
    return {};
  }
  const obj = {};
  for(const item of allItems) {
    if(item.status == 1){
      obj[item.dictCode as keyof {}] = obj[item.dictCode as keyof {}] || [];
      (obj[item.dictCode as keyof {}] as any[]).push(item);
    }
  }
  return obj
  // 将数据字典的值存入state
  // useState('dictList', () => (obj))
  // 取值的时候直接
  // const showDict = useState('dictList')
  // console.log(showDict.value)
  // return true
}


// 排序方法，每次筛选好数据后调用这个排序
export const sortList = async(arr: any) => {
  arr.sort(function(a: any, b: any) { //callback
    if (!a.sort) {
      return 1
    }
    if(!b.sort) {
      return -1
    }
    if (a.sort > b.sort) { // a b 第一个第二个
      return 1  //返回正数 ，b排列在a之前
    } else {
      return -1 //返回负数 ，a排列在b之前
    }
  })
}

// 将api挂在全局，全局掉接口
export const useApi = () => api

// const runtimeConfig = useRuntimeConfig()
// export const fileUrl = runtimeConfig.public.fileUrl
export const fileUrl = 'https://d3dtp8x2mff1ca.cloudfront.net/'

export const getImg = (data: any) => {
  if(data?.ext){
    // console.log(JSON.parse(obj?.ext)[0].split('=')[2])
    // let url = JSON.parse(data?.ext)[0].split('=')[2]
    // // return `http://localhost:3000/api/file/view/aliyunOssFileStorageServiceImpl/${url}`
    // return fileUrl + url

    const ext = JSON.parse(data?.ext) as string[];
    const resized = ext.find(e => e.startsWith('resized:'));
    if (resized) {
      return fileUrl + resized.split('=')[2]
    }
   }else{
    return fileUrl + data?.storageKey
   }
}
// 获取数据字典
// getDictList()
// const { userApi,advertisementApi } = useApi()

// export const currencyNow: any = ref({})
// export const currencyNow = (obj: object | {}) => {
//   currencyNow.value = obj
// }
// currencyNow.value = useState('currencyNow') || {}

// 资讯的标题副标题
export let consultTitle: any = ref({})

// 转换货币数值 但是不计算汇率
export const filterCurrencyOnly = (num: number | 0) => {

	const mainStore = useMainStore()

  const { currencyNow } = storeToRefs(mainStore)
  
  return currencyNow.value?.currencySymbol + num.toFixed(2)
}

export const filterCurrency = (num: number | 0) => {

	const mainStore = useMainStore()

	const { currencyNow } = storeToRefs(mainStore)

  // return currencyNow.value?.currencySymbol + (((0.8 * 100) * 1078) / 100)
  return currencyNow.value?.currencySymbol + (((currencyNow.value?.exchangeRate * 1000) * num) / 1000).toFixed(2)
}

export const filterCurrencyWithBlank = (num: number | 0) => {

	const mainStore = useMainStore()

	const { currencyNow } = storeToRefs(mainStore)

  // return currencyNow.value?.currencySymbol + (((0.8 * 100) * 1078) / 100)
  return currencyNow.value?.currencySymbol + ' ' + (((currencyNow.value?.exchangeRate * 1000) * num) / 1000).toFixed(2)
}

export const filterCurrencyUSD = (num: number | 0) => {

	const mainStore = useMainStore()

	const { currencyNow } = storeToRefs(mainStore)
  return currencyNow.value?.currencySymbol +  (currencyNow.value?.exchangeRate * num).toFixed(2)

  // return currencyNow.value?.currencySymbol + (((0.8 * 100) * 1078) / 100)
  // return currencyNow.value?.currencySymbol + (((currencyNow.value?.exchangeRate * 1000) * num) / 1000).toFixed(2)
}

export const toNewPage = (url: any) => {
  console.log(url);
  
  console.log('进来了：', url)
  if (!url) return
  if (url.indexOf('://') > 0) {
    window.open(url)
  } else {
    // window.location.href = url
    // navigateTo(
    //   {
    //     path: url
    //   },
    //   {
    //     replace: true
    //   }
    // )
    const router = useRouter()
    router.push(url)
  }
}
export const currentIcon = (e:any) =>{
  if(e < 200){
    return '/images/shine.png'
  }else if(e>=200 && e<600){
    return '/images/sparkle.png'
  }else if(e>=600 && e<800){
    return '/images/radiate.png'
  }else if(e >= 800){
    return '/images/prime.png'
  }
}

export const userAvatar = (userInfo: any) => {
  if (!userInfo.score && userInfo.score != 0) {
    return ''
  }

  if (userInfo.score < 200) {
    return '/images/shine.png'
  } else if (userInfo.score < 400) {
    return '/images/shine-1.png'
  } else if (userInfo.score < 800) {
    return '/images/shine-2.png'
  } else {
    return '/images/shine-3.png'
  }
}

const { userApi } = useApi()
export const getDictList = async() => {
  const dictList = localStorage.getItem('dictList')
  // 如果空重新获取数据字典
  if (!dictList) {
    const res = await userApi.getDictList()
    const data = itemsToFastDicts(res?.data)
    // const data = res?.data
    // 数据字典缓存时长30min
    const obj = {...data, expire: new Date().getTime() + 1000 * 60 * 30}
    localStorage.setItem('dictList', JSON.stringify(obj))
    return data
    // await userApi.getDictList().then((res: any) => {
    //   console.log("重新获取字典1。")
    //   // 处理数据字典返回的数据
    //   // const data = itemsToFastDicts(res?.data)
    //   const data = res?.data
    //   const obj = {...data, expire: new Date().getTime() + 1000 * 60 * 1}
    //   localStorage.setItem('dictList', JSON.stringify(obj))
    //   console.log("返回1", data)
    //   return data
    // }).catch(e => {
    //   return []
    // })
  } else {
    const time = new Date().getTime();
    const obj = JSON.parse(dictList || '');
    // 如果未过期，返回缓存的数据字典
    if (time < obj.expire) {
      return obj
    } else {
      // 过期了删除缓存重新获取
      localStorage.removeItem('dictList');
      const res = await userApi.getDictList()
      const data = itemsToFastDicts(res?.data)
      // const data = res?.data
      const obj = {...data, expire: new Date().getTime() + 1000 * 60 * 1}
      localStorage.setItem('dictList', JSON.stringify(obj))
      return data
    }
  }
}

export function selectDictLabel(datas: any, value: string) {
  if (value === undefined) {
    return "";
  }
  var actions = [];
  Object.keys(datas).some((key) => {
    if (datas[key].itemValue == ('' + value)) {
      actions.push(datas[key].itemText);
      return true;
    }
  })
  if (actions.length === 0) {
    actions.push(value);
  }
  return actions.join('');
}




// userApi.getDictList().then((res: any) => {
//   // const { data } = res
//   // 处理数据字典返回的数据
//   const dicts = itemsToFastDicts(res?.data)
//   console.log("获取数据字典，", dicts)
//   const ddd = useCookie('lydict', dicts)
//   // ddd.value = JSON.stringify(dicts)
//   console.log('读取缓存数据：', ddd)
//   console.log('读取缓存数据：', useCookie('lydict'))
//   setTimeout(() => {
//     const look = useCookie('ly-dict')
//     console.log('10s读取缓存数据：', useCookie('lydict'))
//     console.log('10s读取缓存数据：', look)
//   }, 10000);
// })


// userApi.getCommodityShelves().then((res:any) => {
// 	console.log('货架数据',res?.data)
// })


// const initAllPage = () => {

//   userApi.getDictList().then((res: any) => {
//     itemsToFastDicts(res.data)
//   })

//   userApi.getCurrency({}).then((res: any) => {
//     console.log("货币：", res)
//     useState('currencyRate', () => (res.data))
//     currencyNow = useState('currencyNow', () => (res.data[0]??[]))
//   })

//   advertisementApi.getAdvertisement().then((res:any) => {
//     if (!Array.isArray(res.data)) {
//       return {};
//     }
//     const obj = {};
//     for(const item of res.data) {
//       obj[item.advertisementType as keyof {}] = obj[item.advertisementType as keyof {}] || [];
//       (obj[item.advertisementType as keyof {}] as any[]).push(item);
//     }
//     useState('advertisementList', () => (obj))
//   })
// }

// initAllPage()
