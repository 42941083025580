import { Http } from '~/composables/useApiFetch'

export default new class product extends Http {
	// 添加购物车   弃用
	// public addShoppingCart(data:any){
	// 	return this.post('/jm-shopping-cart',data,true)
	// }
	// 添加购物车
	public addShoppingCart(data:any){
		return this.post('/front/customized/shoppingCartAdd',data,true)
  }
  
  // 本地添加购物车
  public addShoppingCartNew(data:any){
		return this.post('/front/customized/shoppingCartAddNew',data,true)
	}

	// 获取购物车
	// public getShoppingCart(data:any){
	// 	return this.get('/jm-shopping-cart',data,true)
	// }
	public getShoppingCart(data:any){
		return this.get('/front/customized/getShoppingCartPage',data,true)
	}

	public getByOrder(data:any){
		return this.post('/front/customized/getByOrder',data,true)
	}
	
	public delGoods(id:string){
		return this.delete('/jm-shopping-cart/'+id,{},true)
	}
	public delGoodsBatch(data:object){
		return this.delete('/jm-shopping-cart/batch',data,true)
	}
	// 清空购物车
	public delGoodsList(body: any){
		return this.delete('/front/jm-shopping-cart/batch', body, true)
	}
	
	// 获取保护计划
	public getProtectionPlan(){
		return this.get('/front/jm-protection-plan',{},true)
	}
	// 获取礼品包装
	public getGift(){
		return this.get('/front/jm-gift-wrap',{},true)
	}
	// 获取商品关联的赠品
	public getActivityGoods(){
		return this.get('/jm-activity-goods',{},true)
  }
  
  // 同步购物车
	public syncShoppingCart(data:any){
		return this.post('/front/customized/synchronousShoppingCartList',data,true)
  }

  // 获取礼品卡信息
  public getGiftCardInfo(code:any) {
    return this.get('/front/customized/cardInfo/' + code, {},true)
  }

  // 获取购物车（无登录态）
  public getShoppingCartNew(data:any){
		return this.post('/front/customized/getShoppingCart',data,true)
	}
	// 更新购物车
	public shoppingCartUpdate(data:any){
		return this.clientPut('/front/customized/shoppingCartUpdate',data,true)
	}
}